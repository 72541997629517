import { createSlice, createAsyncThunk,current } from '@reduxjs/toolkit'
import checkListService from './checkListService'
// NOTE: use a extractErrorMessage function to save some repetition
import { extractErrorMessage } from '../../utils'
import {  getItemsByCheckListID } from '../checklistItem/checkListItemSlice'
import {getItemsDataFromState} from '../checklistItem/checkListItemSlice'


// NOTE: no need for isLoading, isSuccess, isError or message as we can leverage
// our AsyncThunkAction and get Promise reolved or rejected messages at
// component level
const initialState = {
  checkLists: [],
  isLoading: false,
  isError:false



}




// Create new checklist
export const createANewCheckList = createAsyncThunk(
  'checkLists/createANewCheckList',
  async (checkListData, thunkAPI) => {

    try {
      const token = thunkAPI.getState().auth.user.token
      return await checkListService.createANewCheckList(checkListData, token)
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error))
    }
  }
)



// delete a checklist
export const deleteACheckList = createAsyncThunk(
  'checkLists/deleteACheckList',
  async (checkListData, thunkAPI) => {

    try {
      const token = thunkAPI.getState().auth.user.token
      return await checkListService.deleteACheckList(checkListData, token)
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error))
    }
  }
)



// Get user checklists
export const getCheckLists = createAsyncThunk(
  'checkLists/getCheckLists',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const checklistsResponse= await checkListService.getCheckLists(token)

      
       console.log("get checklist service called")

      for (let i=0;i<=2;i++) {
        // Dispatch actions for items if needed
        thunkAPI.dispatch(getItemsByCheckListID(checklistsResponse[i]._id))
       
      }
    
       
      return checklistsResponse
    } catch (error) {
      
      return thunkAPI.rejectWithValue(extractErrorMessage(error))
    }
  }
)


export const checkListSlice = createSlice({
  name: 'checkLists',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false

    },
    addNewChecklistLocally: (state,action) =>{
      
      state.checkLists.push(action.payload)
      
    },
    removeCheckListData:(state)=>{
      state.checkLists=[]
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCheckLists.pending, (state) => {
        // NOTE: clear single ticket on tickets page, this replaces need for
        // loading state on individual ticket
        state.isError=false
        state.checkList = null
        
      })
      .addCase(getCheckLists.rejected, (state) => {
        // NOTE: clear single ticket on tickets page, this replaces need for
        // loading state on individual ticket
        state.isError=true
        
      })
      .addCase(getCheckLists.fulfilled, (state, action) => {
        state.isError=false
        state.checkLists = action.payload

        // let checklistItems_unsortedarr=state.checkListItems
        // let sorted_checklistItems=checklistItems_unsortedarr.forEach((item) => {
        //   item.items.sort((a, b) => {
        //     const statusOrder = { pending: 1, completed: 2 };
        //     return statusOrder[a.status] - statusOrder[b.status];
        //   });
        // });

        


      })
      .addCase(createANewCheckList.pending, (state) => {
        console.log('pending')
        // NOTE: clear single ticket on tickets page, this replaces need for
        // loading state on individual ticket
        
      })
      .addCase(createANewCheckList.rejected, (state,action) => {
        console.log('rejected')
        state.checkLists=initialState
        // NOTE: clear single ticket on tickets page, this replaces need for
        // loading state on individual ticket
        
      })
      .addCase(createANewCheckList.fulfilled, (state, action) => {
       
        // state.checkList = action.payload
        state.checkLists=state.checkLists.filter((item)=>item._id)
        console.log(current(state))
        const itemIndex = state.checkLists.findIndex((item)=>{
            
          return item._id === action.payload._id
          });
          
          if (itemIndex !== -1) {
           // Replace the object at the found index with the updated item
           state.checkLists[itemIndex] = action.payload;
         //  console.log(`Updated item with _id ${itemIdToUpdate}`);
         } else {
           state.checkLists.push(action.payload);
         //  console.log(`Item with _id ${itemIdToUpdate} not found.`);
         }
      //  state.checkLists.find(item => item._id === action.payload._id) || state.checkLists.push(action.payload)
      })
      .addCase(deleteACheckList.pending, (state) => {
        // NOTE: clear single ticket on tickets page, this replaces need for
        // loading state on individual ticket

      })
      .addCase(deleteACheckList.fulfilled, (state, action) => {
        // state.checkList = action.payload

        state.checkLists = state.checkLists.filter(item => item._id !== action.payload._id)
        //  arrayOfObjects = arrayOfObjects.filter(item => item.id !== idToRemove);
      })
      
  },
})

export const { addNewChecklistLocally,removeCheckListData } = checkListSlice.actions;
export default checkListSlice.reducer

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import checkListItemService from './checkListItemService'
// NOTE: use a extractErrorMessage function to save some repetition
import { extractErrorMessage } from '../../utils'
import {current} from '@reduxjs/toolkit'

// NOTE: no need for isLoading, isSuccess, isError or message as we can leverage
// our AsyncThunkAction and get Promise reolved or rejected messages at
// component level
const initialState = {
  checkListItems: [],
  filteredItems:[],
  isLoading: false,
  isFilterApplied:false
  
}



// Create new checklistitem
export const createOrUpdateACheckListItem = createAsyncThunk(
  'checkList_item/create_update',
  async (checkListItemData, thunkAPI) => {
    
    try {
      
      const token = thunkAPI.getState().auth.user.token
      return await checkListItemService.createOrUpdateACheckListItem(checkListItemData, token)
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error))
    }
  }
)


// delete a checklistitem
export const deleteACheckListItem = createAsyncThunk(
  'checkList_item/deleteACheckListItem',
  async (checkListItemData, thunkAPI) => {
    
    try {
      const token = thunkAPI.getState().auth.user.token
      return await checkListItemService.deleteACheckListItem(checkListItemData, token)
    } catch (error) {
      console.log(error)
      return thunkAPI.rejectWithValue(extractErrorMessage(error))
    }
  }
)

// get Items by CheckListID
export const getItemsByCheckListID = createAsyncThunk(
  'checkList_item/getItemsByCheckListID',
  async (checkListID, thunkAPI) => {
    
    try {
      const token = thunkAPI.getState().auth.user.token
      return await checkListItemService.getItemsByCheckListID(checkListID, token)
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error))
    }
  }
)


export const checkListItemsSlice = createSlice({
  name: 'checkListItems',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false

    },
    setItems: (state) => {
      
      state.value += 1;
    },
    filterItems : (state,action) => {
      const filtereditems=state.checkListItems.filter(item=>item.status===action.payload.status)
     
      return {
        ...state,
        filteredItems:
          action.payload.status==='All' ?  [...state.checkListItems]: filtereditems ,
          isFilterApplied:action.payload.status==='All'?false:true
      };
    },
    removeCheckListItemData:(state)=>{
      state.checkListItems=[]
    },
    addNewChecklistItemLocally:(state,action)=>{
      
      state.checkListItems.push(action.payload)
      
    }
  },
  extraReducers: (builder) => {
    builder
    .addCase(deleteACheckListItem.pending, (state, action) => {
     
     })
     .addCase(deleteACheckListItem.rejected, (state, action) => {
      
     
                 
     })
    .addCase(deleteACheckListItem.fulfilled, (state, action) => {
      
      
            state.checkListItems=state.checkListItems.filter((item)=> !(item._id===action.payload.itemID && item.checkListID===action.payload.checkListID))
        
                  
      })
      .addCase(createOrUpdateACheckListItem.fulfilled, (state, action) => {
        
        state.checkListItems=state.checkListItems.filter((item)=>item._id)
        const itemIndex = state.checkListItems.findIndex((item)=>{
            
           return item._id === action.payload._id
           });
           
           if (itemIndex !== -1) {
            // Replace the object at the found index with the updated item
            state.checkListItems[itemIndex] = action.payload;
            
      
          } else {
            state.checkListItems.push(action.payload);
          //  console.log(`Item with _id ${itemIdToUpdate} not found.`);
          }
          
        })
        .addCase(getItemsByCheckListID.fulfilled, (state, action) => {
        
        //  console.log('checkthis',action.payload[0].checkListID?action.payload[0].checkListID:undefined)
              
          state.checkListItems=state.checkListItems.filter((item)=>item.checkListID!==(action.payload[0].checkListID || undefined ))
           state.checkListItems.push(...action.payload)
        
            
          })
  
  },
})

export const { addNewChecklistItemLocally,filterItems,removeCheckListItemData } = checkListItemsSlice.actions;
export default checkListItemsSlice.reducer
